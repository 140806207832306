import { MediaStats } from '../../../../components/MediaStats';
import { AuthorButtons } from '../AuthorButtons';
import { PostStatus, UserPermission, PostSpecies } from '../../../../lib/graphql.document';
import { MediaPost } from '../../types';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { formatInAppTimezone } from '../../../../lib/date-fns';
import { CMS_ROUTES } from '../../../../lib/ROUTES';
import { Button } from '../../../../components/Button';
import { Authors } from './Authors';
import { hasPermission } from '../../../../lib/apollo';
import { getCommentsSectionId } from '../CommentsSection';
import { tw } from '../../../../styles/tw';

interface MetaProps {
  mediaPost: MediaPost;
  removeBorder?: boolean;
}

const Meta = ({ mediaPost, removeBorder }: MetaProps) => {
  const { user } = useLoginInfo();
  const {
    id,
    commentsCount,
    reactions: { likesCount, dislikesCount },
    status,
    publishAt,
    species,
    authors,
    createdBy,
  } = mediaPost;

  const localDate = formatInAppTimezone(publishAt, 'HH:mm, d MMMM yyyy');

  const showAuthorButtons =
    species === PostSpecies.Blog &&
    (createdBy.id === user?.id || hasPermission(user?.permissions ?? [], UserPermission.Moderate));

  const isPublished = status === PostStatus.Published;
  const isOnModeration = status === PostStatus.Moderation;

  return (
    <div
      className={tw(
        'mb-5.5 flex flex-col items-start border-b border-blood pb-4 text-sm lg:mb-0 lg:border-b-0 lg:pb-0',
        removeBorder && 'mb-4 border-b-0 pb-0'
      )}
    >
      <div className="flex">
        {isPublished ? <p className="mr-2.5 text-blood">{localDate}</p> : <p className="mr-2.5">Не опубликовано</p>}
        {isPublished ? <MediaStats comments={commentsCount} scrollToId={getCommentsSectionId(mediaPost.slug)} /> : null}
      </div>
      {species === PostSpecies.Blog ? <Authors authors={authors} /> : null}
      {isPublished && hasPermission(user?.permissions ?? [], UserPermission.WriteArticle) ? (
        <MediaStats likes={likesCount} dislikes={dislikesCount} />
      ) : null}
      {hasPermission(user?.permissions ?? [], UserPermission.WriteArticle) &&
      (species !== PostSpecies.Blog || hasPermission(user?.permissions ?? [], UserPermission.Moderate)) ? (
        <Button to={`${PUBLIC_CONFIG.CMS_HOST}${CMS_ROUTES.POST.buildPath({ id })}`}>Редактировать в CMS</Button>
      ) : null}
      {showAuthorButtons ? (
        <AuthorButtons isPublished={isPublished} isOnModeration={isOnModeration} postId={id} />
      ) : null}
    </div>
  );
};

export { Meta };
